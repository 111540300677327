import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";
import "./InfoCards.css";

export const InfoCards = () => {
  const navigate = useNavigate();

  return (
    <>
      <Col
        span={6}
        xs={24}
        sm={16}
        md={18}
        lg={9}
        xl={7}
        xxl={7}
        className="card-container"
        onClick={() => navigate("/services")}
      >
        <div className="card-image-two">
          <div className="card-info">
            <p className="card-text">Our Services</p>
            <div className="card-text-border"></div>
          </div>
        </div>
      </Col>
      <Col
        span={6}
        xs={24}
        sm={16}
        md={18}
        lg={9}
        xl={7}
        xxl={7}
        className="card-container"
        onClick={() => navigate("/team")}
      >
        <div className="card-image">
          <div className="card-info">
            <p className="card-text">Meet The Team</p>
            <div className="card-text-border"></div>
          </div>
        </div>
      </Col>
      <Col
        span={6}
        xs={24}
        sm={16}
        md={18}
        lg={9}
        xl={7}
        xxl={7}
        className="card-container"
        onClick={() =>
          window.open(
            "https://westforsythanimalhospital.vetsfirstchoice.com/",
            "_blank"
          )
        }
      >
        <div className="card-image-four">
          <div className="card-info">
            <p className="card-text">Online Pharmacy</p>
            <div className="card-text-border"></div>
          </div>
        </div>
      </Col>
    </>
  );
};
