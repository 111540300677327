import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <div className="top-footer">
        <div>
          <h3>Contact Us</h3>
          <p>4350 Kelly Mill Rd</p>
          <p>Cumming, GA, 30040</p>
          <p>(678)-648-6948</p>
        </div>
        <div className="border"></div>
        <div>
          <h3>Our Hours</h3>
          <p>Mon - Thurs: 7:30 AM - 5:30pm</p>
          <p>Fri - Sat: 7:30 AM - 12pm</p>
          <p>Sunday: CLOSED</p>
        </div>
        <div className="border"></div>
        <div>
          <h3>After Hours Care</h3>
          <p>For after hours care:</p>
          <p>Veterinary Emergency Group</p>
          <p>(404)-924-7378</p>
        </div>
      </div>
      <div className="bottom-footer">
        <p>© 2021 WEST FORSYTH ANIMAL HOSPITAL</p>
        <p className="jared">DESIGNED BY JARED BURNETT</p>
      </div>
    </div>
  );
}

export default Footer;
