import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutPage from "./components/AboutPage/AboutPage";
import ServicesPage from "./components/ServicesPage/ServicesPage";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage";
import ContactPage from "./components/ContactPage/ContactPage";
import OurStoryPage from "./components/OurStoryPage/OurStoryPage";
import ServicePage from "./components/ServicePage/ServicePage";
import HospitalServicesPage from "./components/HospitalServicesPage/HospitalServicesPage";
import { IconContext } from "react-icons/lib";
import "antd/dist/antd.css";
import TopNav from "./components/TopNav/TopNav";
import NavBar from "./components/NavBar/NavBar";
import TourPage from "./components/TourPage/TourPage";

function App() {
  return (
    <BrowserRouter>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <div span={24} className="App">
          <TopNav />
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/our-story" element={<OurStoryPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route
              path="/services/:category/:service"
              element={<ServicePage />}
            />
            <Route
              path="/hospital-policies"
              element={<HospitalServicesPage />}
            />
            <Route path="/forms" element={<ResourcesPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/team" element={<AboutPage />} />
            <Route path="/tour" element={<TourPage />} />
          </Routes>
        </div>
      </IconContext.Provider>
    </BrowserRouter>
  );
}

export default App;

export const services = {
  generalWellness: [
    {
      title: "Diet & Nutrition",
      imageLarge: "./diet-large.webp",
      link: "diet",
      image: "./diet-dog-eating.webp",
      description:
        "Proper diet and nutrition can help your pet fight against disease, maintain a proper weight, and promote the overall well-being of your pet.",
      overview:
        "Maintaining a healthy body weight is a very important aspect of a pet's overall physical health. Good nutrition can help provide your pet with a happier, safer, and longer life. There are many nutritional supplements that can help fight against disease, maintain a proper weight, and promote the overall well-being of any animal.",
      extra: [
        {
          title: "Why is maintaining a healthy weight important?",
          description:
            "Obesity is a common problem among pets, as it can be easy to overfeed a pet that knows how to beg. Being overweight is a serious problem for animals, and can cause health problems as they get older. With proper diet and exercise, all pets should be able to meet their dietary needs and be within a healthy weight range. The best way to make sure your pet’s needs are being met is to consult with us about a specific diet for your pet and their lifestyle. Our staff will help your pet battle unhealthy weight gain and counsel you on the best nutritional options available.",
        },
        {
          title: "When is a diet change needed?",
          description:
            "Pets should be fed a balanced wellness diet based on a number of factors, including their life stage, their breed or size, activity level, and any health issues they have. Diet changes are recommended when they enter a new life stage. For example, transitioning is recommended from puppy or kitten to adult, or from adult to senior, which is usually around age seven. Diet changes may also be recommended if your pet develops any issues that could be treated with a prescription diet, such as obesity or a food allergy.",
        },
      ],
    },
    {
      title: "Dentistry",
      imageLarge: "./dentistry-large.webp",
      link: "dentistry",
      image: "./dentistry.webp",
      description:
        "Annual dental exams and cleanings are recommended to protect your pet from many health problems and help them maintain a healthy and clean mouth.",
      overview:
        "Studies show that 50% of all dogs and cats have some form of periodontal disease. That number jumps to 80% in pets that are 3 years of age or older. If left untreated, periodontal disease can cause infection, pain, and tooth loss over time. It can also lead to serious health problems like microscopic changes in the heart, liver, and kidneys. Because of this, we recommend an annual veterinary dental healthcare examination for all pets.",
      extra: [
        {
          title: "Why do pets need dental care?",
          description:
            "Many health problems start in the mouth. Plaque, tartar, periodontal disease, and infected teeth serve as a source of inflammation and infection for the rest of the body. Dental disease is one of the most common problems that we see in dogs and cats. It can cause drooling, reluctance to eat, swelling, bad breath, redness of the gums, loose teeth and tooth discoloration.",
        },
        {
          title: "When should I seek dental care for my pet?",
          description:
            "Dental issues and dental related diseases can easily be prevented by visiting our veterinarians regularly for dental examinations and cleanings. We take a comprehensive approach to dental care including dental health assessment, treatment, and prevention.",
        },
        {
          title: "Teeth Exams, Cleaning and Polishing",
          description:
            "Dog and cat dental cleanings are very similar to human dental cleanings, except that we are required to use anesthesia to properly and safely examine and clean the teeth. After the cleaning, our veterinarians perform a thorough oral exam and check for signs of disease like gum loss, root exposure, or pockets around the root. Also similar to human dentistry, we do full mouth radiographs (x-rays) of your pet. This allows our veterinarians to be able to evaluate the roots of your pet's teeth as well as any disease or abnormalities that are located below the gum line and not visible on examination alone.",
        },
        {
          title: "Tooth Extractions",
          description:
            "We make every effort to save teeth that we feel have a chance to be successfully treated. In many circumstances, however, periodontal disease is so advanced that treatment without extraction is unsuccessful. We only extract teeth that in the doctor's opinion are beyond saving.",
        },
        {
          title: "Minor Oral Surgery",
          description:
            "Many teeth require oral surgery to safely remove each individual root. We have extensive training and experience to perform these procedures properly. Pain medications are administered in clinic and provided for in-home aftercare.",
        },
      ],
    },
    {
      title: "Parasite Control (Fleas, Ticks & Heartworms)",
      imageLarge: "./parasite-large.webp",
      link: "parasite",
      image: "./parasite-cat.webp",
      description:
        "Parasites such as fleas and ticks can be very damaging to your pet’s health. Preventive measures should be taken year-round to inhibit potential outbreaks.",
      overview:
        "The idea of your pet being infested with parasites is a disturbing thought, but it’s also a medical issue that can have serious consequences. Parasites can diminish quality of life and even cause life-threatening health issues. Common internal parasites include heartworms, roundworms, hookworms, whipworms and tapeworms. The most frequent external parasites include fleas and ticks. Your pet should be free from parasites, both internal and external.",
      extra: [
        {
          title: "Why are parasites dangerous?",
          description:
            "Fleas are small, wingless, brown, fast moving insects you can see in your pet’s fur. Some animals are allergic to the saliva of fleas, which can cause inflammation and more discomfort. If ingested while your pet is grooming themselves, fleas can transmit an intestinal parasite called tapeworm. Flea infestations can lead to anemia and fleas are also capable of transmitting serious diseases. \n \n Ticks are members of the spider family and live in cracks and crevices in the home or outside in vegetation such as grassy meadows, woods, brush, and weeds. Some tick bites only cause mild irritation or swelling at the site, but other tick bites can infect your pet with serious illnesses. If left untreated, these diseases, such as lyme, can lead to more severe health problems or even be fatal. \n \n Heartworms are transmitted when an infected mosquito bites your pet. Heartworms are parasites that live in the heart and its surrounding blood vessels. The adult heartworm produces offspring called microfilariae, which circulate in the pet’s blood. Unrecognized and untreated heartworm infections can be fatal.",
        },
        {
          title: "When should I seek treatment for parasites?",
          description:
            "If your pet is showing signs of fleas such as continuous scratching, gnawing or licking, schedule an appointment immediately. Additionally, flea dirt, the byproduct of fleas that looks like coffee grounds or pepper, can usually be seen by looking at your pet’s abdomen or by combing your pet’s coat with a fine-tooth comb. \n \n If you see a tick on your pet, do not try and burn it off with a match. This does not work and could harm your pet. It’s much safer to have one of our trained professionals remove the tick for you. Make an appointment with us immediately to limit the impact of the tick. \n \n The best treatment for heartworms is prevention. One of the most common and effective prevention methods is a monthly oral medication. The parasite is transmitted through mosquito bites and usually the pet shows no signs for months. Later symptoms include difficulty breathing, coughing, weight loss, tiring easily and listlessness. In many cases pets do not show outward signs until advanced stages of the disease. An annual blood test is recommended to screen for heartworms. This disease can be treated if found early, but it can be costly.",
        },
        {
          title: "How can I prevent parasites?",
          description:
            "Our veterinary team is happy to help you choose the correct preventive regimen based on your pet’s risk factors and health status. It is important to discuss with us yearly which pest control products are ideal for your household based on the everyday life of your pet.",
        },
      ],
    },
    {
      title: "Wellness & Preventive Care",
      imageLarge: "./wellness-large.webp",
      link: "wellness",
      image: "./wellness-image.webp",
      description:
        "Annual wellness exams evaluate your pets overall health, detect problems before they become serious, and keep them on track to live a long, healthy life.",
      overview:
        "We follow your pet through the many phases of their lives. From specialized and preventative care during their younger years to regular wellness checks as they age, we seek to prevent and detect problems before they arise. We know your pet is important to you, that's why we work hard to ensure they are given only the best in care through all the years of their lives. \n \n The American Animal Hospital Association recommends that your pet have a wellness exam at least once every year. As your pet ages, they recommend having even more. We believe strongly in preventive care and have developed wellness protocols for every stage of the life of your pet. Depending on your pet’s life stage, vaccines may be administered, as well as disease screenings.",
      extra: [
        {
          title: "Why are wellness exams important?",
          description:
            "Wellness exams give us a chance to evaluate the overall health of your pet while also giving us a baseline for their own unique bodies and tendencies. They also allow us to detect potential problems before they become serious, and even make your pet more familiar with our veterinary hospital, making future visits easier on them and you.",
        },
        {
          title: "When should my pet visit the vet?",
          description:
            "We believe in preventative medicine as the best approach to keeping our patients healthy. This includes annual wellness examinations, vaccinations on a one- or three-year basis, external and internal parasite prevention, and dietary management. Our doctors will help determine what vaccinations are appropriate according to you and your pet’s needs. We will discuss in detail what your options are to help you make the best possible health decisions for your pet.",
        },
        {
          title: "How do wellness exams work?",
          description:
            "During a wellness exam, our veterinary team will start out by taking your pet's vital signs. Our veterinarian will perform a comprehensive examination of your pet from nose to tail and may also perform diagnostic tests such as blood work, urinalysis, or intestinal parasite tests. Depending on your pet’s life stage, vaccines may be administered, as well as disease screenings for heartworm, kidney, liver, blood disorders, and more. \n \n Wellness examinations do not necessarily mean that your pet is going to be subjected to complicated or costly medical tests and treatments. This allows us to prevent major health issues from developing undetected and gives us the opportunity to analyze and record baseline values such as temperature, body condition, and lab work. We measure these when your pet is healthy in order to provide better care in the event of illness or emergency. A dental health exam and a weight check are always important parts of a wellness exam, and may indicate problems that can progress rapidly and have significant impact on your pet's overall health. \n \n Early detection and correction of medical problems will help to ensure that your pet will live a long, healthy, and active life.",
        },
      ],
    },
    {
      title: "Vaccinations",
      imageLarge: "./vaccinations-large.webp",
      link: "vaccinations",
      image: "./vaccinations-puppy.webp",
      description:
        "Preventative care is an important aspect in maintaining your pet’s health. Proper vaccination is vital in protecting them against harmful diseases.",
      overview:
        "We believe that preventative care is one of the most important aspects of maintaining your pet’s health. There are a variety of diseases that affect animals, so proper vaccination of your pet is vital in protecting them from the many types of illnesses to which they are susceptible to.",
      extra: [
        {
          title: "Why are vaccinations important?",
          description:
            "Vaccinations are vital to the health and protection of your pet, and serve as a preventive measure in combating viral/bacterial diseases like Parvovirus, Parainfluenza virus, Distemper, Leptospirosis, Panleukopenia, Feline Leukemia Virus, and Rabies.",
        },
        {
          title: "When should my pet get vaccinated?",
          description:
            "Vaccinations are particularly important for puppies, kittens, and other young animals that have immature immune systems. Veterinary vaccinations generally begin at 6-8 weeks of age and then boostered throughout your pet’s life depending on the vaccine. Our doctors will determine the appropriate vaccination plan for your pet.",
        },
        {
          title: "How are vaccines administered?",
          description:
            "Vaccines help to combat diseases by exposing the pet's immune system to inactive or small amounts of a particular form of bacteria or virus. Vaccines are administered through a subcutaneous injection (under the skin), orally, or intra-nasally, depending on the vaccine. \n \n Vaccinations are accompanied by a consultation and examination with our veterinarians to make certain that your pet's condition is stable enough to receive them. Proper and timely administration is necessary to ensure optimal protection.",
        },
      ],
    },
    {
      title: "Microchipping",
      imageLarge: "./microchipping-large.webp",
      link: "microchipping",
      image: "./microchipping.webp",
      description:
        "Microchip identification is the most reliable way to reunite lost pets with their owners.",
      overview:
        "Microchip identification helps return an estimated 8,000 lost cats, dogs, and other animals to their homes every day. Pet ID chips are the most reliable way to reunite pets with their owners and provide peace of mind.",
      extra: [
        {
          title: "Why should I microchip my pet?",
          description:
            "Microchip identification is the most reliable way to find lost pets and return them home with their owners. The procedure is safe, easy, and inexpensive.",
        },
        {
          title: "When should I microchip my pet?",
          description:
            "Some owners feel that their pet doesn't need identification because they stay indoors, but in our experience, these are the most likely to become lost when they accidentally get outside. As separation from your cat or dog can happen all too easily, permanent identification by microchipping is critical. Every pet should have microchip identification as the procedure can be done at any age.",
        },
        {
          title: "How does microchipping work?",
          description:
            "The small microchip (the size of a grain of rice) is injected under the skin between the shoulder blades of your pet in a relatively harmless veterinary procedure. The chip is permanent, lasting the whole life of the pet. Your information has to be registered with your bar code number so that you can be contacted if your pet ever becomes lost. \n \n If a pet is found, all animal care facilities (shelters, hospitals, etc.) will scan the pet for a microchip using a special microchip reader that is simply waved over the skin. Once a microchip is found, a special hotline is called, and the lost pet is reported. The pet owner is then called immediately and given the contact information about where to pick up their pet.",
        },
      ],
    },
  ],
  diagnosticServices: [
    {
      title: "Radiography",
      imageLarge: "./radiography-large.webp",
      link: "radiography",
      image: "./radiography-image.webp",
      description:
        "We’re equipped to perform routine radiography services to identify many types of illness or injury when pets are sick or suffer a trauma.",
      overview:
        "Radiography, also known as X-rays, is one of the most common and valuable medical diagnostic tools. X-rays are highly useful for screening areas of the body that have contrasting tissue densities, or when evaluating solid tissues.",
      extra: [
        {
          title: "Why would my pet need x-rays?",
          description:
            "If your pet is sick or has suffered a trauma, X-rays provide a minimally invasive tool to help our doctors diagnose your pet. X-rays are also used in general wellness exams to diagnose potential problems before they become serious.",
        },
        {
          title: "When is X-ray testing appropriate?",
          description:
            "We may recommend veterinary X-rays as part of a diagnostic procedure if your pet is experiencing any health conditions or as a preventive measure in a routine senior wellness examination. We use radiology alone or in conjunction with other diagnostic tools depending on the patient’s condition. We’re fully equipped to perform routine radiology services to identify many types of illness or injury when pets are sick or suffer a trauma.",
        },
        {
          title: "How is X-ray testing used?",
          description:
            "X-rays can be used to detect a variety of ailments in animals including arthritis, tumors, bladder and kidney stones, and lung abnormalities such as pneumonia. They are also used to evaluate bone damage, the gastrointestinal tract, respiratory tract, genitourinary system, organ integrity, and even identify foreign objects that may have been ingested. Dental radiographs help distinguish healthy teeth from those that may need to be extracted, and identify any abnormalities beneath the gums including root damage, tumors, and abscesses. In some cases, we may need to sedate your pet or use short-acting general anesthesia.",
        },
      ],
    },
    {
      title: "Diagnostic Ultrasounds",
      imageLarge: "./diagnostics-large.webp",
      link: "diagnostic-ultrasounds",
      image: "./diagnostic-ultrasounds.webp",
      description:
        "An ultrasound is a highly useful tool when evaluating heart conditions, internal organs, cysts and tumors, and diagnosing pregnancy.",
      overview: "",
      extra: [
        {
          title: "Why would my pet need an ultrasound?",
          description:
            "A veterinary ultrasound is an invaluable resource for evaluating heart conditions. It can detect alterations in abdominal organs and assist in the recognition of any cysts and tumors that may be present. Many times, x-rays will be utilized in combination with an ultrasound as they reveal the size, dimension, and position of the organ. With the ability for real-time monitoring, ultrasounds are also utilized for pregnancy diagnosis and development monitoring.",
        },
        {
          title: "When would my pet get an ultrasound test?",
          description:
            "An ultrasound is excellent at evaluating your pet's internal organs. An ultrasound is usually recommended when our doctors find abnormalities on bloodwork or x-rays, or to monitor a disease process.",
        },
        {
          title: "How does ultrasound testing work?",
          description:
            "Ultrasound equipment directs a narrow beam of high frequency sound waves into the area of interest. The sound waves either transmit through, reflect, or absorb in the tissues that they encounter. Any ultrasound waves that are reflected will return as echoes and convert into an image that is displayed on the monitor, giving a 2-dimensional image of the tissues under examination. With the ability to obtain real time information, outcomes can often be determined immediately. \n \n The exam is completely painless. Light sedation may used to help the patient lie comfortably while the scan is being performed. Your pet may need to be shaved in the area of interest, as veterinary ultrasound images are of better quality if they have complete contact with the skin.",
        },
      ],
    },
    {
      title: "EKG",
      imageLarge: "./ekg-large.webp",
      link: "ekg",
      image: "./ekg-image.webp",
      description:
        "We use electrocardiography to measure and evaluate the electrical activity of the heart to diagnose potential heart conditions your pet may suffer from",
      overview:
        "Electrocardiography, also referred to as EKG, is a diagnostic tool used to measure the electrical activity of the heart. The recorded measurements are then reviewed by a cardiologist to screen for or monitor any heart conditions.",
      extra: [
        {
          title: "Why would my pet need electrocardiography?",
          description:
            "If your pet is having heart-related health issues, diagnostic tools such as an EKG or Echocardiogram can be used to help diagnose the problem and provide direction to your doctor as to treatment options.",
        },
        {
          title: "When would my pet need electrocardiography?",
          description:
            "Our doctors may recommend an EKG if they hear that your pet has a heart murmur during an exam, if we are planning to schedule anesthesia for your senior pet, or if they are experiencing any symptoms of heart related conditions. If your pet is experiencing symptoms such as lethargy, fainting, shortness of breath, or other irregular behaviors, contact us immediately as those could be indications of heart disease.",
        },
        {
          title: "How does electrocardiography work?",
          description:
            "Veterinarians use EKG testing and echocardiograms to evaluate your pet's heart. They are both noninvasive, safe with no risk of exposure to radiation, and only take a few minutes. They’re performed typically with the pet lying down on a padded table. \n \n The EKG is an effective tool that measures the electrical efficiency of the heart. It’s commonly used to evaluate the heart rhythm, identify abnormalities in the heartbeat, and potential damage to the heart muscle and tissue. An echocardiogram uses ultrasound technology, or high-frequency sound waves, produced by a transducer and directed towards the chest. These two exams used together can greatly increase the accuracy of diagnosis of many heart conditions for your dog or cat.",
        },
      ],
    },
    {
      title: "In-House Laboratory",
      imageLarge: "./lab-services-large.webp",
      link: "laboratory",
      image: "./laboratory-services.webp",
      description:
        "Diagnostic testing can identify problems your pet may be experiencing so that proper treatment can begin before a condition worsens.",
      overview:
        "Unfortunately, pets can’t tell us where it hurts, and oftentimes, a healthy appearing animal may be masking symptoms of disease or illness. We recommend veterinary diagnostic testing to identify any problems and to begin treatment as early as possible. These tests are especially helpful when symptoms are unspecific or hard to define.",
      extra: [
        {
          title: "When would laboratory work be needed?",
          description:
            "Most laboratory work is done through routine wellness exams as directed by our doctors. For emergencies or unscheduled visits, our diagnostic lab will run various tests to determine the best treatment for your pet. \n \n We recommend regular tests to determine your pet’s health. If any concerns or conditions are found, we will help you decide on proper treatment. If your pet is showing irregular behavior, please schedule an appointment as soon as possible. Our doctors will determine which laboratory services are appropriate during your exam.",
        },
        {
          title: "How do veterinarians use laboratory work?",
          description:
            "To ensure a proper diagnosis, we first examine your pet by looking at their eyes, ears, and skin; then we check their cardiovascular, neurological, gastrointestinal, and skeletal systems for any abnormalities. We will then perform blood and/or urine tests if necessary to check their kidneys, liver, pancreas, and endocrine system. Based on your pet’s condition, we may recommend further diagnostic testing. \n \n If you’re concerned that something may be wrong with your pet, please call us to schedule a medical assessment.",
        },
      ],
    },
  ],
  surgeryServices: [
    {
      title: "Spay & Neuter",
      imageLarge: "./spay-neuter-large.webp",
      link: "spay-neuter",
      image: "./spay-neuter-service.webp",
      description:
        "Spaying or neutering your pet can help them live a longer, healthier life, minimize behavior problems, and help control the population of unwanted dogs and cats.",
      overview:
        "According to the American Veterinary Medical Association, nearly 12,500 puppies are born in the United States each hour. Spaying females or castrating males eliminates unwanted litters, which contribute to thousands of euthanasia procedures and millions of stray animals. Additionally, these procedures can minimize behavior problems and help your pet live a longer, healthier life by reducing the likelihood of certain cancers and tumors.",
      extra: [
        {
          title: "When should I have my pet spayed or neutered?",
          description:
            "In general, we recommend spaying or castrating small dogs and cats between 4-6 months of age. With large breed dogs, we often recommend delaying the surgery until they are 6-12 months of age.",
        },
        {
          title: "Why should I have my pet spayed or neutered?",
          description:
            "There are many benefits that come with spaying your female companion animal. They include helping to control the stray dog and cat population, eliminating the sometimes 'messy' heat cycles that attract male dogs, and preventing diseases in your pet such as pyometra (infection in the uterus) and mammary cancer. Additionally, research has shown that spayed pets live longer than pets that have not been spayed. \n \n There are also many benefits that come with castrating your male companion animal. These benefits include helping to control the stray dog and cat population, eliminating undesirable and embarrassing behavior, and preventing diseases in your pet such as prostate disease and testicular cancer.",
        },
        {
          title: "How is a spay or neutering surgery performed?",
          description:
            'Spaying, also called an "ovariohysterectomy," is a surgical procedure in which both ovaries and uterus are completely removed from your female pet while they are under general anesthesia. Castrating refers to the surgical procedure in which both testicles are removed while your male pet is under general anesthesia. \n \n Before the operation, we will assess your pet to minimize risk. While your pet is under anesthesia we take individual care for the safety of each pet. Our certified technicians and doctors are trained in advanced monitoring to ensure your pet\'s comfort. \n \n Your pet’s safety and comfort are our primary concerns when performing a spay or castration. We routinely use a IV catheter on canine spays and castrations, as well as feline spays.  This is important for allowing immediate IV access in the event of an emergency.  We use advanced pain management techniques in conjunction with anesthesia to make sure your pet is as comfortable as possible  the procedure and after they are discharged. Our spay and castration patients receive 2-3 different injectable pain medications during the procedure and will go home with oral pain medication. We may also perform local anesthetic blocks at the surgical site. Proper pain management makes the procedure as comfortable as possible and allows for faster recovery.)',
        },
      ],
    },
    {
      title: "Soft Tissue Surgery",
      imageLarge: "./soft-tissue-large.webp",
      link: "soft-tissue-surgery",
      image: "./soft-tissue-service.webp",
      description:
        "We perform soft tissue surgery for a number of medical reasons. This common surgery can be used for most anything non-joint or bone related.",
      overview:
        "Soft tissue surgery is any surgery non-joint or bone related, and can include ear, nose and throat, cardio-thoracic, hepatic, gastrointestinal, urogenital, skin reconstructive and oncological surgeries. If soft tissue surgery is recommended for your pet, we will do everything possible to keep them safe and comfortable before, during, and after the surgery.",
      extra: [
        {
          title: "Why would my pet need soft tissue surgery?",
          description:
            "Veterinary soft tissue surgery is recommended for a variety of reasons. The most common soft tissue surgeries for animals are hernia repairs and mass removals. More advanced soft tissue surgeries include cystotomy, abdominal exploratory surgery, and splenectomy.",
        },
        {
          title: "When would soft tissue surgery be needed?",
          description:
            'Soft tissue surgeries are used for a wide array of medical conditions. These include "routine" procedures such as mass removals, trauma, and emergency surgery, wound management, and reconstructive procedures.',
        },
        {
          title: "How do you care for my pet during surgery?",
          description:
            "Our veterinarians adhere to the highest level of care standards for all surgical procedures. Our highly skilled doctors place the utmost emphasis on pain management to ensure your pet is safe and comfortable throughout the treatment process. We believe that keeping our patients safe and comfortable before, during and after surgery is of the greatest importance and an essential component of your pet's care.",
        },
      ],
    },
  ],
  regenerativeMedicine: [
    {
      title: "Stem Cell Therapy",
      imageLarge: "./stem-cell.webp",
      link: "stem-cell",
      image: "./stem-cell.webp",
      description:
        "Actistem Therapy is a same day, affordable, all-natural option for pets suffering from arthritis, hip dysplasia, degenerative joint disease, soft tissue injuries, and more.",
      overview:
        "Stem cells are unspecialized cells that are capable of renewing themselves through cell division. Under certain conditions, they can become a specific tissue or organ cell. Stem cells serve as an internal repair system by replacing damaged tissues with healthy ones. Due to injury and/or age sometimes our own repair abilities can use a jump-start. Stem Cell Therapy allows your Veterinarian to use your pet's own stem cells isolated from fat tissue in the body and apply this repair ability to areas of need. We use Stem Cell Therapy to treat osteoarthritis, hip dysplasia, soft tissue injuries, degenerative joint disease and some other inflammatory diseases. We also have the ability to extract stem cells during a routine procedure such as a spay or neuter. The cells will then be banked for the lifetime of your pet and will be available when needed in the future.",
      extra: [],
    },
    {
      title: "PRP Therapy",
      imageLarge: "./prp.webp",
      link: "prp-therapy",
      image: "./prp.webp",
      description:
        "Platelet- Rich Plasma, or PRP, is a non-surgical and advanced means of providing high doses of the body's own healing factors directly to the area where they are needed.",
      overview:
        "Platelets that are found in whole blood contain many healing growth factors and bio active proteins. These proteins can initiate healing and assist with inflammation. This therapy can also help a pet suffering from arthritis, wound care, post surgical healing, and more. In veterinary medicine, PRP is most often used for tendon/ligament injuries and osteoarthritis. Other cases where PRP therapy is helpful include: corneal ulcers, stomatitis in cats, and wound healing. PRP offers an affordable and all-natural alternative to drugs and steroids.",
      extra: [],
    },
  ],
  reproductionServices: [
    {
      title: "Labor & Delivery",
      imageLarge: "./labor-delivery-large.webp",
      link: "labor-delivery",
      image: "./ultrasound-image.webp",
      description:
        "If your pet is pregnant or you are planning for pregnancy, consult with our doctors to have the right delivery plan.",
      overview:
        "The creation of life is an exciting time. We are here to support and help with your pet's labor and delivery with trained doctors, technicians and modern technology. Giving birth at our hospital is the safest and most comfortable option for your pet.",
      extra: [
        {
          title:
            "When should I bring my pet to the hospital for labor and delivery?",
          description:
            "If your pet has become pregnant or if you suspect your pet is pregnant, make an appointment with our veterinarians. We will perform a pre-natal exam as well as an ultrasound to determine how many puppies or kittens you can expect. \n \n As your pet nears the end of pregnancy, look for signs that labor is beginning. These signs include restlessness, anxiety, panting, pacing, nesting, refusal to eat, or even trembling. This stage of labor can last anywhere between 6-36 hours, during which contractions are occurring. If your pet is expressing signs of labor, call our hospital immediately.",
        },
        {
          title: "How do you provide care during labor and delivery?",
          description:
            "Our primary focus is the health and comfort of your pet and the newborn animals. We will observe and support each stage of labor and delivery to ensure things go smoothly. If complications arise during labor, our veterinarians and technicians will treat your pet as needed.",
        },
      ],
    },
    {
      title: "Artificial Insemination for Pets",
      imageLarge: "./bulldog-large.webp",
      link: "artificial",
      image: "./bulldog-image.webp",
      description:
        "Artificial insemination is an alternative way to breed animals. Our veterinarian practice can help you determine if artificial insemination is the best method of reproduction for your pet.",
      overview:
        "When natural mating is not possible or preferred, some pet owners turn to artificial insemination to breed animals. We offer various artificial insemination techniques that produce less hassle for the owner and less trauma for the animals.",
      extra: [
        {
          title: "Why would my pet need artificial insemination?",
          description:
            "Artificial insemination has become a popular method of mating for animals who are unable to naturally reproduce. Some breeders actually prefer artificial insemination to successfully reproduce their animals. Artificial insemination benefits include increased conception rates and increased safety of the animals involved.",
        },
        {
          title: "When can my pet be artificially inseminated?",
          description:
            "The perfect time to inseminate your pet will depend on the breed and age of the animal. Other factors your veterinarian will take into consideration are the female animal’s fertile cycle, and the quality/count of the male animal’s sperm. The option of freezing or chilling semen allows flexibility in the artificial insemination practice. \n \n Since each pet is different, we will determine the optimal time to inseminate (typically 5 – 7 days before the start of the female animal’s cycle).",
        },
      ],
    },
  ],
  endOfLifeCareServices: [
    {
      title: "Euthanasia",
      imageLarge: "./flower1-large.webp",
      link: "euthanasia",
      image: "./flower1-image.webp",
      description:
        "Euthanizing a pet is not an easy decision. We are here to discuss options and assist in every way we can during this difficult time.",
      overview:
        "Euthanasia is commonly referred to as “putting down” or “putting to sleep”. This is a very hard decision many pet owners must make at the last stage of their pet’s life.",
      extra: [
        {
          title: "Why euthanasia?",
          description:
            "Just as we all age, so do our beloved pets. As much as we would love for all of our pets to be in our lives forever, there comes a time to say goodbye. Euthanasia offers a peaceful, controlled option for the end of our pet's life. Based on your pet's quality of life, euthanasia may be something to consider.",
        },
        {
          title: "When is the time right?",
          description:
            "Knowing when the time is right can be a difficult choice. There are many factors that go into deciding to euthanize an animal, and it is a personal issue that differs from pet owner to pet owner. Your top concern should be about your pet’s quality of life, difficulties that come with old age, and the pain they may be suffering.",
        },
        {
          title: "What should I expect?",
          description:
            "Our euthanasia process is designed to be as peaceful as possible. Once you and the doctor have determined that this is the best choice, your pet will have a drug administered that results in deep sedation. During the time it takes to reach this state, you and your family may remain with your pet. It is a quiet few minutes of saying goodbye. You may also choose to leave at this point, having bid your pet farewell, taking comfort in knowing it was calm and pain free.",
        },
        {
          title: "How should I get more information or ask for help?",
          description:
            "Our veterinarians are available to discuss your options. They are very familiar with the experience, and are able to talk about the process and the feelings that come with it. Please make an appointment or contact us for information on options, timelines, and references for support through the grieving process.",
        },
      ],
    },
    {
      title: "Cremation",
      imageLarge: "./flower1-image.webp",
      link: "cremation",
      image: "./flower2-image.webp",
      description:
        "There are a number of wonderful ways to memorialize your beloved pet. Cremation can be a lovely way to honor and celebrate their lives.",
      overview:
        "Dealing with the end of a pet’s life is never easy. We want to make the process less difficult for you by offering cremation services as a way to memorialize and honor the life of your beloved pet.",
      extra: [
        {
          title: "Why should I consider cremation for my pet?",
          description:
            "Cremation can be a wonderful and flexible way to celebrate and memorialize the life of an animal. Cremated remains can be buried, kept in an indoor or outdoor urn, or commemorated in jewelry or keepsake items.",
        },
        {
          title: "When should I consider cremation for my pet?",
          description:
            "We all wish our pets could live forever, but unfortunately there comes a time where we have to say goodbye. Please consult with us as close to end of the pet's life as possible, so we can determine the most humane and appropriate end of life care.",
        },
      ],
    },
  ],
};

// {
//   title: "",
//   image: '',
//   description: '',
//   overview: '',
//   extra: [
//     {
//       title: "",
//       description: "",
//     },
//     {
//       title: "",
//       description: "",
//     },
//     {
//       title: "",
//       description: "",
//     },
//   ],
// },
