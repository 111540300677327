import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./ResourcesPage.css";

function ResourcesPage() {
  useEffect(() => {
    (function () {
      document.documentElement.scrollTop = 0;
    })();
  }, []);
  return (
    <div>
      <div className="about-banner">
        <p>Forms</p>
      </div>
      <Footer />
    </div>
  );
}

export default ResourcesPage;
