export const testimonials = {
  test1: {
    name: "Turner Family",
    info: "Our family has been seeing Dr. Erin Doiron for over 10 years. Dr. Doiron's bedside manner during regular visits and distressed visits has always been very calm, loving and attentive to our animals, ourselves and our children. You can tell immediately that Dr. Doiron loves being a veterinarian.  We have been extremely happy with her and are very thankful for her.",
  },
  test2: {
    name: "Weigand Family",
    info: "Dr. Doiron has been caring for my pets for over 11 years.  My dog Cooper had severe hip and elbow dysplasia.  She administered stem cell treatments and at 4 years old he was much more active and could stand on his back legs for the first time in his life.  Dr. Doiron explained that eventually the stem cell treatments would be less effective as the bone damage could not be repaired.  I appreciated her honesty and that enabled me to recognize when Cooper felt it was time for him to go.  Saying goodbye to Cooper was very difficult and I was so glad Dr. Doiron was with us. Dr. Doiron is a great veterinarian, very easy to talk to, friendly, kind and compassionate.  My dogs love her very much and are always so happy to see her.  I feel so lucky to have found her and I know she gives my pets the very best care.",
  },
  test3: {
    name: "Turner Family",
    info: "Our family has been seeing Dr. Erin Doiron for over 10 years. Dr. Doiron's bedside manner during regular visits and distressed visits has always been very calm, loving and attentive to our animals, ourselves and our children. You can tell immediately that Dr. Doiron loves being a veterinarian.  We have been extremely happy with her and are very thankful for her.",
  },
  test4: {
    name: "Turner Family",
    info: "Our family has been seeing Dr. Erin Doiron for over 10 years. Dr. Doiron's bedside manner during regular visits and distressed visits has always been very calm, loving and attentive to our animals, ourselves and our children. You can tell immediately that Dr. Doiron loves being a veterinarian.  We have been extremely happy with her and are very thankful for her.",
  },
  testOn5: {
    name: "Burnett Family",
    info: "This is a really good review for a really good clinic. 10/10 would come here all the time. Good stuff.",
  },
};
