import React, { useState } from "react";
import Footer from "../Footer/Footer";
import "./AboutPage.css";
import { Col, Divider, Image, Row, Typography } from "antd";
import DoironOne from "../../assets/images/doiron/Doiron_8.jpg";
import DoironTwo from "../../assets/images/doiron/Doiron_9.jpg";
import DoironThree from "../../assets/images/doiron/Doiron_15.jpg";
import CaldwellOne from "../../assets/images/caldwell/Caldwell_7.jpeg";
import CaldwellTwo from "../../assets/images/caldwell/Caldwell_12.jpeg";
import CaldwellThree from "../../assets/images/caldwell/Caldwell_15.jpeg";
import AdamsOne from "../../assets/images/adams/Adams_2.jpeg";
import AdamsTwo from "../../assets/images/adams/Adams_4.jpg";
import AdamsThree from "../../assets/images/adams/Adams_11.jpg";
import Syd from "../../assets/images/techs/syd.jpeg";
import Eric from "../../assets/images/techs/eric.jpeg";
import Jenna from "../../assets/images/techs/jenna.jpeg";
import Christina from "../../assets/images/techs/christina.jpeg";
import { clientServiceReps, technicians } from "../../utils/staffInfo";
import { useMediaQuery } from "react-responsive";

function AboutPage() {
  const isSmall = useMediaQuery({
    query: "(max-width: 600px)",
  });
  // useEffect(() => {
  //   (function () {
  //     document.documentElement.scrollTop = 0;
  //   })();
  // }, []);

  return (
    <div>
      <div className="about-banner">
        <p>Meet Our Team</p>
      </div>

      {/* VETERINARIANS */}
      <Row justify="center">
        <Col span={18}>
          <Typography.Title
            style={{
              textAlign: "center",
              color: "#6daaa5",
              fontSize: isSmall ? "42px" : "64px",
              fontWeight: 300,
              marginTop: 20,
              marginBottom: 0,
            }}
          >
            Veterinarians
          </Typography.Title>
          <Row justify="center">
            <Col span={4}>
              <Divider />
            </Col>
          </Row>

          {/* DR DOIRON */}
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                color: "#6daaa5",
                fontSize: isSmall ? "32px" : "52px",
                fontWeight: 200,
                marginTop: 20,
                marginBottom: 0,
              }}
            >
              Dr. Erin Doiron
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                fontWeight: 400,
                marginBottom: 0,
                fontSize: isSmall ? "20px" : undefined,
                color: "rgba(0,0,0,.7)",
              }}
              level={2}
            >
              Owner, Veterinarian
            </Typography.Title>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Row gutter={[16, 16]}>
                <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Image
                    preview={false}
                    src={DoironTwo}
                    height={isSmall ? 400 : 500}
                    width={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </Col>
                <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Image
                    preview={false}
                    src={DoironThree}
                    height={isSmall ? 400 : 500}
                    width={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Image
                preview={false}
                src={DoironOne}
                height={isSmall ? 400 : 600}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Typography.Text style={{ fontSize: 18, fontWeight: 300 }}>
              Erin Doiron, DVM founded West Forsyth Animal Hospital in 2022. She
              previously worked at Alpharetta Animal Hospital since the age of
              15 before opening WFAH. She knew at a young age that her dream was
              to be a Veterinarian. Her areas of special interest are
              endocrinology, oncology, surgery, and reproduction. She also has a
              love for regenerative medicine, specifically Stem Cell Therapy.
              <br />
              <br /> Dr. Doiron attended The University of Georgia for
              undergraduate school and went on to receive her Doctorate of
              Veterinary Medicine from the University of Georgia College of
              Veterinary Medicine. She is a die hard Dawg fan!!
              <br />
              <br /> Dr. Doiron is a member of the American Veterinary Medical
              Association as well as the Georgia Veterinary Medical Association.
              She is also on the board for the Forsyth County Humane Society and
              the Alpharetta Public Safety Foundation as well as an active
              member of the Alpharetta Rotary Club. She spends her Sundays
              volunteering with elementary school kids at Browns Bridge Church.
              Serving her community is a very important part of her life. <br />
              <br /> Dr. Doiron is married to her husband, Stephen, who is often
              seen helping around the hospital. They have 2 daughters and more
              animals then can be counted! She enjoys spending time with her
              family and pets, traveling, camping, shopping, boating, watching
              the girls in their sports activities and going to dog shows with
              their Frenchies.{" "}
            </Typography.Text>
          </Row>

          {/* DR SULLIVAN */}
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                color: "#6daaa5",
                fontSize: isSmall ? "32px" : "52px",
                fontWeight: 200,
                marginTop: 20,
                marginBottom: 0,
              }}
            >
              Dr. Pam Sullivan
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                fontWeight: 400,
                marginBottom: 0,
                fontSize: isSmall ? "20px" : undefined,
                color: "rgba(0,0,0,.7)",
              }}
              level={2}
            >
              Veterinarian
            </Typography.Title>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Row gutter={[16, 16]}>
                <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Image
                    preview={false}
                    src={CaldwellThree}
                    height={isSmall ? 400 : 500}
                    width={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </Col>
                <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Image
                    preview={false}
                    src={CaldwellOne}
                    height={isSmall ? 400 : 500}
                    width={"100%"}
                    style={{ objectFit: "cover" }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Image
                preview={false}
                src={CaldwellTwo}
                height={isSmall ? 400 : 600}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Typography.Text style={{ fontSize: 18, fontWeight: 300 }}>
              Dr. Sullivan grew up in South Florida and moved to Georgia at the
              very beginning high school. She attended the University of Georgia
              for her undergraduate degree and Veterinary School. After
              graduation, she entered private practice. She has practiced small
              animal medicine since 1999. <br />
              <br />
              She currently lives in Cumming with her two children, Lauren and
              Patrick and her husband, Clay. She has two King Charles Cavaliers,
              Maybelline and Scout and a black Lab named Delta. Leo and Spencer
              (the cats) also add to the rambunctious house. <br />
              <br /> On any given day not at the office or working with animals,
              you can find her gardening in the yard with Clay. They love to be
              outside working with the plants. She also enjoys watching the kids
              in their soccer or basketball games. And of course, you will find
              them ALL cheering on the Georgia Bulldogs!
            </Typography.Text>
          </Row>
        </Col>
      </Row>

      {/* MANAGEMENT */}
      <Row justify="center">
        <Col span={18}>
          <Typography.Title
            style={{
              textAlign: "center",
              color: "#6daaa5",
              fontSize: isSmall ? "42px" : "64px",
              fontWeight: 300,
              marginTop: 20,
              marginBottom: 0,
            }}
          >
            Management
          </Typography.Title>
          <Row justify="center">
            <Col span={4}>
              <Divider />
            </Col>
          </Row>

          {/* TAYLOR ADAMS */}
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                color: "#6daaa5",
                fontSize: isSmall ? "32px" : "52px",
                fontWeight: 200,
                marginTop: 20,
                marginBottom: 0,
              }}
            >
              Taylor Adams
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Title
              style={{
                textAlign: "center",
                fontWeight: 400,
                marginBottom: 0,
                fontSize: isSmall ? "20px" : undefined,
                color: "rgba(0,0,0,.7)",
              }}
              level={2}
            >
              Practice Manager
            </Typography.Title>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Image
                preview={false}
                src={AdamsOne}
                height={400}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Image
                preview={false}
                src={AdamsTwo}
                height={400}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Col>
            <Col span={12} xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Image
                preview={false}
                src={AdamsThree}
                height={400}
                width={"100%"}
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Typography.Text style={{ fontSize: 18, fontWeight: 300 }}>
              Taylor Adams grew up in Alpharetta, GA and started working in the
              veterinary field in 2008, managing a local practice for the last
              five years. Her passion is caring for animals and providing an
              exceptional customer experience. She attended the University of
              Georgia for her Agribusiness undergraduate degree and rode on the
              UGA equestrian team (Go Dawgs!). <br />
              <br />
              Taylor lives in Milton with her husband, Drew and two children,
              Carly and Grant. They have two dogs, a wire-haired Dachshund named
              Camden and a rescue lab mix named Wally. Her favorite place to be
              is the outdoors with her family- camping, spending time at the
              lake, or barn!
            </Typography.Text>
          </Row>
        </Col>
      </Row>

      {/* Veterinary Technicians and Veterinary Assistants */}
      <Row justify="center">
        <Col span={18}>
          <Typography.Title
            style={{
              textAlign: "center",
              color: "#6daaa5",
              fontSize: isSmall ? "42px" : "64px",
              fontWeight: 300,
              marginTop: 40,
              marginBottom: 0,
            }}
          >
            Veterinary Technicians and Veterinary Assistants
          </Typography.Title>
          <Row justify="center">
            <Col span={4}>
              <Divider />
            </Col>
          </Row>
          {technicians.map((technician) => (
            <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
              <Col span={8} xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <Image
                  preview={false}
                  src={technician.image}
                  height={400}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Col>
              <Col span={8} xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                <Row>
                  <Typography.Title
                    style={{
                      textAlign: "center",
                      color: "#6daaa5",
                      fontSize: isSmall ? "32px" : "52px",
                      fontWeight: 200,
                      marginBottom: 0,
                    }}
                  >
                    {technician.name}
                  </Typography.Title>
                </Row>
                <Row>
                  <Typography.Title
                    style={{
                      textAlign: "center",
                      fontWeight: 400,
                      marginBottom: 0,
                      fontSize: isSmall ? "20px" : undefined,
                      color: "rgba(0,0,0,.7)",
                    }}
                    level={2}
                  >
                    {technician.title}
                  </Typography.Title>
                </Row>
                <Row style={{ marginTop: 25 }}>
                  <Typography.Text style={{ fontSize: 18, fontWeight: 300 }}>
                    {technician.biography}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>

      {/* Client Service Representatives  */}
      <Row justify="center" style={{ marginBottom: 80 }}>
        <Col span={18}>
          <Typography.Title
            style={{
              textAlign: "center",
              color: "#6daaa5",
              fontSize: isSmall ? "42px" : "64px",
              fontWeight: 300,
              marginTop: 40,
              marginBottom: 0,
            }}
          >
            Client Service Representatives
          </Typography.Title>
          <Row justify="center">
            <Col span={4}>
              <Divider />
            </Col>
          </Row>
          {clientServiceReps.map((clientServiceRep) => (
            <Row gutter={[16, 16]} style={{ marginTop: 25 }}>
              <Col span={8} xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <Image
                  preview={false}
                  src={clientServiceRep.image}
                  height={400}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Col>
              <Col span={8} xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                <Row>
                  <Typography.Title
                    style={{
                      textAlign: "center",
                      color: "#6daaa5",
                      fontSize: isSmall ? "32px" : "52px",
                      fontWeight: 200,
                      marginBottom: 0,
                    }}
                  >
                    {clientServiceRep.name}
                  </Typography.Title>
                </Row>
                <Row>
                  <Typography.Title
                    style={{
                      textAlign: "center",
                      fontWeight: 400,
                      marginBottom: 0,
                      fontSize: isSmall ? "20px" : undefined,
                      color: "rgba(0,0,0,.7)",
                    }}
                    level={2}
                  >
                    {clientServiceRep.title}
                  </Typography.Title>
                </Row>
                <Row style={{ marginTop: 25 }}>
                  <Typography.Text style={{ fontSize: 18, fontWeight: 300 }}>
                    {clientServiceRep.biography}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>

      <Footer />
    </div>
  );
}

export default AboutPage;
