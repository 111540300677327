import Syd from "../assets/images/techs/syd.jpeg";
import Eric from "../assets/images/techs/eric.jpeg";
import Jenna from "../assets/images/techs/jenna.jpeg";
import Christina from "../assets/images/techs/christina.jpeg";
import Lauren from "../assets/images/techs/lauren.jpeg";
import Jax from "../assets/images/techs/jax.jpeg";
import Brooke from "../assets/images/techs/brook.jpeg";
import Tammy from "../assets/images/techs/tammy.jpeg";

export const technicians = [
  {
    name: "Sydney",
    title: "Veterinary Technician",
    biography:
      'Sydney is one of our Veterinary Technicians. Sydney enjoys live music, scrapbooking, and going for a jog outdoors. She also really loves guinea pigs!! Everything about them. Please stop by and say "Hi" to Sydney. You will love her as much as we do.',
    image: Syd,
  },
  {
    name: "Eric",
    title: "Veterinary Technician",
    biography:
      "Eric, here with his sweet girl Sadie, is another one of our Veterinary Technicians. Eric is an avid rock climber, loves to play ultimate frisbee and enjoys hiking/going to the river with Sadie. He will take great care of your pets so stop by and meet him.",
    image: Eric,
  },
  {
    name: "Jenna",
    title: "Veterinary Technician",
    biography:
      "Jenna is one of our Veterinary Technicians. She is pictured here with her beloved dog Bronson but really is a cat lover at heart. She loves painting and crafting, hiking and fishing, and spending time with her family and furkids. She is looking forward to meeting your cats (and dogs).",
    image: Jenna,
  },
  {
    name: "Christina",
    title: "Veterinary Technician",
    biography:
      "Christina, here with one of her dogs Meeka, is one of our Veterianry Technicians. She has worked with all kinds of animals from dogs and cats to horses and exotics as well as worked in ER medicine. She enjoys cooking, reading and spending time with her husband and 3 children. Please welcome Christina.",
    image: Christina,
  },
  {
    name: "Lauren",
    title: "Veterinary Assistant",
    biography:
      "Lauren is one of our Veterinary Assistants here at WFAH. Lauren is pictured here with her favorite kitty, Leo. Lauren enjoys playing soccer, going to the beach/lake, and spending time outdoors. If she looks very familiar, it may be because she is Dr. Sullivan's daughter. We are so excited to have her as part of the team.",
    image: Lauren,
  },
];

export const clientServiceReps = [
  {
    name: "Jax",
    title: "Client Service Representative",
    biography: "Jax, pictured here with her dog Ruby, is our third Client Service Representative. She has worked in this role for many years and is very excited to join our team. We are equally as excited to have her. Jax enjoys all things outdoors such as camping, hiking, and kayaking as well as spending time with her husband and baby girl. Please feel free to stop by and say Hi.",
    image: Jax,
  },
  {
    name: "Brooke",
    title: "Client Service Representative",
    biography: "Brooke, seen here with one of her dogs Miles, is one of our Client Service Representatives. She enjoys spending time at the lake, playing with her nephew and building cool things out of wooden pallets. She is looking forward to helping all of our new clients and meeting their fur babies. Please welcome Brooke.",
    image: Brooke,
  },
  {
    name: "Tammy",
    title: "Client Service Representative",
    biography: "Tammy, pictured here with her dogs Lucy and Clarke, is another Client Service Representative here at WFAH. Tammy enjoys tennis, photography and spending time with her husband and 2 boys. She is new to the field of Veterinary Medicine and we are so excited to have her as part of our team. She loves all kinds of animals and can't wait to spend her days with the pets of our wonderful clients.",
    image: Tammy,
  },
];
