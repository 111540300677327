import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Map.css";
import {
  LocationMarkerIcon,
  MailIcon,
  PencilIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/solid";

export default function Map() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_o1736sn",
        "template_0w39rai",
        form.current,
        "user_lRHzs3QCX2giyTYDM38Bl"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="email-map-section">
      <div className="email-form">
        <div className="form-header">
          <MailIcon width={34} className="mail-icon" />
          <h2 style={{ marginBottom: 0 }}>Write to us</h2>
        </div>
        <form action="https://formsubmit.co/contact@westforsythanimalhospital.com" method="POST">
          <div className="user-email-form">
            <label>
              <UserIcon width={42} className="email-input-icon" />
            </label>
            <input type="text" name="user_name" />
          </div>
          <div>
            <label>
              <MailIcon width={42} className="email-input-icon" />
            </label>
            <input type="email" name="user_email" />
          </div>
          <div>
            <label>
              <PencilIcon width={42} className="email-input-icon" />
            </label>
            <textarea name="message" />
          </div>
          <input style={{cursor: 'pointer'}} className="email-submit-button" type="submit" value="Submit" />
        </form>
      </div>
      <div className="map-icon-container">
        <iframe
          frameBorder="0"
          className="map"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13199.38385419272!2d-84.1980493!3d34.2014119!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20e726ee71a6a588!2sWest%20Forsyth%20Animal%20Hospital!5e0!3m2!1sen!2sus!4v1656710804160!5m2!1sen!2sus"
          title="google maps location"
        ></iframe>
        <div className="icons-container">
          <div>
            <LocationMarkerIcon className="bottom-map-icon" />
            <p>4350 Kelly Mill Rd</p>
          </div>
          <div>
            <PhoneIcon className="bottom-map-icon" />
            <p>(678) 648-6948</p>
          </div>
          <div>
            <MailIcon className="bottom-map-icon" />
            <p>contact@westforsythanimalhospital.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}
