import { Col, Row, Typography } from "antd";
import React from "react";
import ComingSoon from "../ComingSoon/ComingSoon";

const TourPage = () => {
  return (
    <Row justify="center" style={{ marginTop: 200 }}>
      <Col span={24}>
        <Row justify="center">
          <ComingSoon />
        </Row>
      </Col>
      <Col>
        <Row justify="center">
          <Typography.Title>Coming Soon...</Typography.Title>
        </Row>
      </Col>
    </Row>
  );
};

export default TourPage;
