import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { MenuIcon } from "@heroicons/react/solid";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./NavBar.css";
import {
  Button,
  Collapse,
  List,
  ListItemButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Drawer, Row, Typography } from "antd";
import { GrClose } from "react-icons/gr";
import { motion } from "framer-motion";
import "antd/dist/antd.css";
import { FiShoppingCart } from "react-icons/fi";

function NavBar() {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 890px)",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [patientAnchorEl, setPatientAnchorEl] = React.useState(null);
  const patientOpen = Boolean(patientAnchorEl);
  const handlePatientClick = (event) => {
    setPatientAnchorEl(event.currentTarget);
  };
  const handlePatientClose = () => {
    setPatientAnchorEl(null);
  };

  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [openPatientSide, setOpenPatientSide] = React.useState(false);

  const handleSideMenu = () => {
    setOpenSideMenu(!openSideMenu);
  };

  const handleNavigaton = (link) => {
    navigate(link);
    setAnchorEl(null);
    setPatientAnchorEl(null);
    setShowNavMenu(false);
    setOpenSideMenu(false);
  };

  const [showNavMenu, setShowNavMenu] = useState(false);

  return (
    <div className="nav">
      {!isMediumScreen && showNavMenu && (
        <Drawer
          headerStyle={{ background: "rgb(34, 34, 34)" }}
          drawerStyle={{ background: "rgb(34, 34, 34)" }}
          visible={showNavMenu}
          placement="right"
          onClose={() => setShowNavMenu(false)}
        >
          <List
            sx={{ color: "white" }}
            closeIcon={<GrClose color="white" style={{ color: "white" }} />}
          >
            {navItems.map((navItem) => (
              <motion.div initial={{ x: 100 }} animate={{ x: 0 }}>
                <ListItemButton
                  onClick={() => handleNavigaton(navItem.link)}
                  color={"primary"}
                  sx={{ p: 2, fontSize: "1.05rem" }}
                >
                  <Typography.Text
                    style={{ textTransform: "none", color: "white" }}
                  >
                    {navItem.title}
                  </Typography.Text>
                </ListItemButton>
              </motion.div>
            ))}
            {/* <motion.div initial={{ x: 100 }} animate={{ x: 0 }}>
              <ListItemButton
                onClick={() => setOpenPatientSide(!openPatientSide)}
                sx={{ p: 2, fontSize: "1.05rem" }}
              >
                <Typography.Text
                  style={{ textTransform: "none", color: "white" }}
                >
                  Patient Center
                </Typography.Text>
                {openPatientSide ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </motion.div>
            <Collapse in={openPatientSide} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {patientCenterItems.map((item) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigaton(item.link)}
                  >
                    <Typography.Text
                      style={{ textTransform: "none", color: "white" }}
                    >
                      {item.title}
                    </Typography.Text>
                  </ListItemButton>
                ))}
              </List>
            </Collapse> */}
            <motion.div initial={{ x: 100 }} animate={{ x: 0 }}>
              <ListItemButton
                onClick={handleSideMenu}
                sx={{ p: 2, fontSize: "1.05rem" }}
              >
                <Typography.Text
                  style={{ textTransform: "none", color: "white" }}
                >
                  About Us
                </Typography.Text>
                {openSideMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </motion.div>
            <Collapse in={openSideMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {dropDownItems.map((item) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleNavigaton(item.link)}
                  >
                    <Typography.Text
                      style={{ textTransform: "none", color: "white" }}
                    >
                      {item.title}
                    </Typography.Text>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <motion.div initial={{ x: 100 }} animate={{ x: 0 }}>
              <li style={{ paddingLeft: 10 }}>
                <button
                  className="book-button"
                  onClick={() =>
                    window.open(
                      "https://app.mavenlink.com/redirect?url=https%3A%2F%2Fwfah.use1.ezyvet.com%2Fexternal%2Fportal%23googtrans%28en%29",
                      "_blank"
                    )
                  }
                >
                  Pet Portal
                </button>
              </li>
            </motion.div>
          </List>
        </Drawer>
      )}
      <nav className="nav-container">
        <div className="bottom-nav-container">
          <img
            className="nav-logo"
            src="logo.jpeg"
            width={175}
            onClick={() => navigate("/")}
            alt="logo"
          />
          {isMediumScreen ? (
            <ul className="nav-links" style={{ marginBottom: 0 }}>
              {navItems.map((navItem) => (
                <Button
                  onClick={() => navigate(navItem.link)}
                  sx={{ fontSize: "1.15rem" }}
                  color={"secondary"}
                >
                  <Typography.Text style={{ textTransform: "none" }}>
                    {navItem.title}
                  </Typography.Text>
                </Button>
              ))}
              {/* <Button
                id="basic-button"
                aria-controls={patientOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={patientOpen ? "true" : undefined}
                onClick={handlePatientClick}
                sx={{ fontSize: "1.15rem" }}
                color={"secondary"}
              >
                <Typography.Text style={{ textTransform: "none" }}>
                  Patient Center
                </Typography.Text>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={patientAnchorEl}
                open={patientOpen}
                onClose={handlePatientClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {patientCenterItems.map((item) => (
                  <MenuItem onClick={() => handleNavigaton(item.link)}>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu> */}
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ fontSize: "1.15rem" }}
                color={"secondary"}
              >
                <Typography.Text style={{ textTransform: "none" }}>
                  About Us
                </Typography.Text>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {dropDownItems.map((item) => (
                  <MenuItem onClick={() => handleNavigaton(item.link)}>
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
              <li>
                <Row align="middle">
                  <button
                    className="book-button"
                    onClick={() =>
                      window.open(
                        "https://app.mavenlink.com/redirect?url=https%3A%2F%2Fwfah.use1.ezyvet.com%2Fexternal%2Fportal%23googtrans%28en%29",
                        "_blank"
                      )
                    }
                  >
                    Pet Portal
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://westforsythanimalhospital.vetsfirstchoice.com/",
                        "_blank"
                      )
                    }
                    style={{
                      background: "white",
                      padding: 16,
                      width: 150,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      fontSize: 16,
                      color: "#6daaa5",
                      outline: "none",
                      border: "1px solid #6daaa5",
                      cursor: "pointer",
                    }}
                  >
                    <FiShoppingCart style={{ marginRight: 10, color: '#6daaa5' }} /> Shop Now
                  </button>
                </Row>
              </li>
            </ul>
          ) : (
            <MenuIcon
              onClick={() => setShowNavMenu(!showNavMenu)}
              className="nav-links"
              width={32}
              cursor={"pointer"}
            />
          )}
        </div>
      </nav>
    </div>
  );
}

const navItems = [
  { link: "/", title: "Home" },
  { link: "/services", title: "Services" },
];

const dropDownItems = [
  { title: "Our Story", link: "our-story" },
  { title: "Meet Our Team", link: "team" },
  { title: "Hospital Tour", link: "tour" },
  { title: "Hospital Policies", link: "hospital-policies" },
];

const patientCenterItems = [
  { title: "Surgery Release Form", link: "surgery-release" },
  { title: "Dental Release Form", link: "dental-release" },
  { title: "Microchip Form", link: "microchip-form" },
];

export default NavBar;
