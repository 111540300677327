import { Col, Image, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { services } from "../../App";
import Footer from "../Footer/Footer";
import "./ServicePage.css";

const ServicePage = () => {
  const { category, service } = useParams();
  const serviceInUse = services[category].find((s) => s.link === service);
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 575px)",
  });

  useEffect(() => {
    (function () {
      document.documentElement.scrollTop = 0;
    })();
  }, []);

  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <div className="about-banner" style={{ marginBottom: 50 }}>
          <p>Our Services</p>
        </div>
        <Row
          justify="center"
          style={{ marginBottom: 50, maxWidth: "100vw" }}
          gutter={[20, 20]}
        >
          <Col
            span={15}
            sm={15}
            md={15}
            lg={15}
            xl={15}
            xs={20}
            style={isSmallScreen ? { textAlign: "center" } : undefined}
          >
            <Typography.Title
              style={{
                textAlign: "center",
                color: "#6daaa5",
                fontSize: "64px",
                fontWeight: 300,
                marginTop: 20,
              }}
            >
              {serviceInUse.title}
            </Typography.Title>
            <Typography.Text style={{ fontSize: 20 }}>
              {serviceInUse.description}
            </Typography.Text>
          </Col>
          <Col span={15} sm={15} md={15} lg={15} xl={15} xs={20}>
            <Image src={serviceInUse.imageLarge} preview={false} />
          </Col>
          <Col
            span={15}
            sm={15}
            md={15}
            lg={15}
            xl={15}
            xs={20}
            style={isSmallScreen ? { textAlign: "center" } : undefined}
          >
            <Typography.Title
              style={{
                color: "#6daaa5",
                marginBottom: 10,
                fontSize: "52px",
                fontWeight: 300,
                marginTop: 20,
              }}
            >
              Overview
            </Typography.Title>
            <Typography.Text style={{ fontSize: 17 }}>
              {serviceInUse.overview}
            </Typography.Text>
          </Col>
          {serviceInUse.extra.map((info, i) => (
            <Col
              span={15}
              sm={15}
              md={15}
              lg={15}
              xl={15}
              xs={20}
              style={isSmallScreen ? { textAlign: "center" } : undefined}
              key={i}
            >
              <Typography.Title
                style={{
                  color: "#6daaa5",
                  marginBottom: 10,
                  fontSize: "52px",
                  fontWeight: 300,
                  marginTop: 20,
                }}
              >
                {info.title}
              </Typography.Title>
              <Typography.Text style={{ fontSize: 17 }}>
                {info.description}
              </Typography.Text>
            </Col>
          ))}
        </Row>
        <Col style={{ marginTop: 50 }}>
          <Footer />
        </Col>
      </Col>
    </Row>
  );
};

export default ServicePage;
