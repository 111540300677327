import React, { useEffect } from "react";
import { services } from "../../App";
import Footer from "../Footer/Footer";
import "./ServicesPage.css";
import "antd/dist/antd.css";
import { Button, Col, Divider, Image, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function ServicesPage() {
  const navigate = useNavigate();
  const {
    generalWellness,
    diagnosticServices,
    surgeryServices,
    regenerativeMedicine,
    reproductionServices,
    endOfLifeCareServices,
  } = services;
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 575px)",
  });
  useEffect(() => {
    (function () {
      document.documentElement.scrollTop = 0;
    })();
  }, []);

  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <div className="about-banner">
          <p>Our Services</p>
        </div>
        <Row
          justify="center"
          align="middle"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Row>
            <Typography.Title
              style={{
                color: "#6daaa5",
                fontSize: "64px",
                fontWeight: 300,
                marginTop: 50,
                marginBottom: 60,
              }}
            >
              General Wellness
            </Typography.Title>
          </Row>

          <Row gutter={[0, 35]}>
            {generalWellness.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(`/services/generalWellness/${section.link}`)
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Typography.Title
            style={{
              color: "#6daaa5",
              fontSize: "64px",
              fontWeight: 300,
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            Diagnostic Services
          </Typography.Title>
          <Row gutter={[0, 35]}>
            {diagnosticServices.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/services/diagnosticServices/${section.link}`
                          )
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Typography.Title
            style={{
              color: "#6daaa5",
              fontSize: "64px",
              fontWeight: 300,
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            Surgery Services
          </Typography.Title>
          <Row gutter={[0, 35]}>
            {surgeryServices.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(`/services/surgeryServices/${section.link}`)
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Typography.Title
            style={{
              color: "#6daaa5",
              fontSize: "64px",
              fontWeight: 300,
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            Regenerative Medicine
          </Typography.Title>
          <Row gutter={[0, 35]}>
            {regenerativeMedicine.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/services/regenerativeMedicine/${section.link}`
                          )
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Typography.Title
            style={{
              color: "#6daaa5",
              fontSize: "64px",
              fontWeight: 300,
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            Reproduction Services
          </Typography.Title>
          <Row gutter={[0, 35]}>
            {reproductionServices.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/services/reproductionServices/${section.link}`
                          )
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Typography.Title
            style={{
              color: "#6daaa5",
              fontSize: "64px",
              fontWeight: 300,
              marginTop: 60,
              marginBottom: 60,
            }}
          >
            End of Life Care
          </Typography.Title>
          <Row gutter={[0, 35]} style={{ marginBottom: 85 }}>
            {endOfLifeCareServices.map((section) => (
              <Col span={12} sm={24} md={24} lg={24} xl={12} xs={24}>
                <Row justify="center" align="start">
                  <Image
                    preview={false}
                    src={section.image}
                    style={
                      isSmallScreen
                        ? {
                            borderRadius: 10,
                            height: 300,
                            width: 300,
                            objectFit: "cover",
                            marginBottom: 20,
                          }
                        : {
                            borderRadius: 10,
                            width: 234,
                            height: 234,
                            objectFit: "cover",
                          }
                    }
                  />
                  <Col
                    style={
                      isSmallScreen
                        ? { margin: "0 20px", textAlign: "center" }
                        : { margin: "0 20px" }
                    }
                    span={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    xs={20}
                  >
                    <Typography.Title
                      style={{
                        color: "#6daaa5",
                        fontSize: 24,
                        fontWeight: 200,
                        marginBottom: 0,
                      }}
                    >
                      {section.title}
                    </Typography.Title>
                    <Typography.Text style={{ fontSize: "1rem" }}>
                      {section.description}
                    </Typography.Text>
                    <Row justify={isSmallScreen ? "center" : undefined}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/services/endOfLifeCareServices/${section.link}`
                          )
                        }
                        style={{ marginTop: 20 }}
                      >
                        Learn More
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Row>
        <Footer />
      </Col>
    </Row>
  );
}

export default ServicesPage;
