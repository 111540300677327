import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./ContactPage.css";

function ContactPage() {
  useEffect(() => {
    (function () {document.documentElement.scrollTop = 0})()
  }, [])
  return (
    <div>
      <div className="about-banner">
        <p>Contact Us</p>
      </div>
      <Footer />
    </div>
  );
}

export default ContactPage;
