import { Col, Row, Typography } from 'antd'
import React from 'react'
import Footer from '../Footer/Footer'

const HospitalServicesPage = () => {
  return (
    <Row justify="center" align="middle">
    <Col span={24}>
      <div className="about-banner" style={{ marginBottom: 50 }}>
        <p>Hospital Policies</p>
      </div>
      <Row justify="center" style={{ marginBottom: 50, maxWidth: '100vw' }} gutter={[20, 20]}>
        <Col span={15}>
          <Typography.Title style={{ marginTop: "10px", marginBottom: '30px' }}>
          Appointment Policy
          </Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
          To allow ample time for all patients and scheduled surgical procedures, we operate primarily by appointment. Emergency cases shall always receive top priority, which is why occasional appointment delay is inevitable. Please realize that we make a sincere attempt to see each client on time. <br /><br /> For your convenience, drop-off appointments are available. A 'drop-off' means you could bring your pet at the time that works best for you and leave him/her with us for a couple of hours. Usually we will ask you to drop off sometime in the morning so our doctors can examine the patient in between appointments or at the time purposely reserved for admitted patients. Once the doctor is done, he or she will give you a call to go over the diagnosis and to give you discharge instructions. <br /><br /> For the safety of all animals in our care, we require that all vaccinations be up to date. Even though we make every effort to make our patients feel comfortable during visits, they may be a little uneasy about new people, new surroundings and other pets. We recommend that animals be placed on a leash or in pet carriers before entering the waiting room.
          </Typography.Text>
        </Col>
        <Col span={15}>
          <Typography.Title style={{ marginTop: "10px", marginBottom: '30px' }}>Payment Policy</Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
          Payment is due at the time services are rendered. If you have any special considerations regarding payment, please discuss this with the receptionist prior to seeing the doctor. We accept a variety of payment options, including cash, Visa, Mastercard, Discover, American Express, CareCredit, and ScratchPay. 
          </Typography.Text>
        </Col>
        <Col span={15}>
          <Typography.Title style={{ marginTop: "10px", marginBottom: '20px' }}>CareCredit</Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
          CareCredit is a personal line of credit for healthcare, both human and veterinary, that works like a credit card. To apply, <a href='https://www.carecredit.com/apply/'>click here for the CareCredit Online Credit Application</a> or call their 24/7 phone number at 1-800-365-8295. 
          </Typography.Text>
        </Col>
        <Col span={15}>
          <Typography.Title style={{ marginTop: "10px", marginBottom: '20px' }}>ScratchPay</Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
          ScratchPay offers financing options ranging from $200 - $10,000. Visit <a href="https://scratchpay.com/consumer?clinic_id=13191">scratchpay.com</a> or text PAY to 213-296-0817 to apply. 
          </Typography.Text>
        </Col>
        <Col span={15}>
          <Typography.Title style={{ marginTop: "10px", marginBottom: '20px' }}>Pet Insurance</Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
          Pet insurance is also a viable option for many pet owners, helping them pay the cost of unexpected veterinary care. There are a variety of pet insurance companies and plans. We have partnered with Trupanion to provide in-hospital, same day insurance claims to make payments automatic and easy for everyone. Please contact us today if you have any questions regarding pet insurance.
          </Typography.Text>
        </Col>
      </Row>
      <Col style={{ marginTop: 50 }}>
        <Footer />
      </Col>
    </Col>
  </Row>
  )
}

export default HospitalServicesPage