import React from "react";
import { useMediaQuery } from "react-responsive";
import "./TopNav.css";

function TopNav() {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 890px)",
  });

  return (
    <div>
      <div className="top-nav-container">
        <div className="nav-hours">
          <span className="phone-number">(678)-648-6948</span>
          {isMediumScreen ? (
            <span>
              {" "}
              | Monday - Thursday 7:30am to 5:30pm | Friday - Saturday 7:30am to 12:00pm
            </span>
          ) : (
            <span className="wfah-top"> | West Forsyth Animal Hospital</span>
          )}
        </div>
        <div className="top-nav-icons">
          <img src="./facebook2.png" width={17} alt="facebook" onClick={() => window.open('https://www.facebook.com/West-Forsyth-Animal-Hospital-102815178755449', '_blank')}/>
          <img src="./instagram.png" width={16} alt="instagram" onClick={() => window.open('https://www.instagram.com/WestForsythAnimalHospital/', '_blank')}/>
          <img src="./email.png" width={20} alt="email" onClick={() => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=contact@westforsythanimalhospital.com', '_blank')}/>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
