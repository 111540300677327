import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    
<div class="🐕">
  <div class="torso">
    <div class="fur">
      <div class="spot"></div>
    </div>
    <div class="neck">
      <div class="fur"></div>
      <div class="head">
        <div class="fur">
          <div class="snout"></div>          
        </div>
        <div class="ears">
          <div class="ear">
            <div class="fur"></div>
          </div>
          <div class="ear">
            <div class="fur"></div>
          </div>
        </div>
        <div class="eye"></div>
      </div>
      <div class="collar">
        
      </div>
    </div>
    <div class="legs">
      <div class="leg">
        <div class="fur"></div>
        <div class="leg-inner">
          <div class="fur"></div>
        </div>
      </div>
      <div class="leg">
        <div class="fur"></div>
        <div class="leg-inner">
          <div class="fur"></div>
        </div>
      </div>
      <div class="leg">
        <div class="fur"></div>
        <div class="leg-inner">
          <div class="fur"></div>
        </div>
      </div>
      <div class="leg">
        <div class="fur"></div>
        <div class="leg-inner">
          <div class="fur"></div>
        </div>
      </div>
    </div>
    <div class="tail">
      <div class="tail">
        <div class="tail">
          <div class="tail -end">
            <div class="tail">
              <div class="tail">
                <div class="tail">
                  <div class="tail"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default ComingSoon