import React, { useEffect } from "react";
import Map from "../Map/Map";
import Footer from "../Footer/Footer";
import "./HomePage.css";
import { Col, Image, Row, Typography } from "antd";
import { OfferCard } from "../OfferCard/OfferCard";
import BarImage from "../../assets/images/bar.svg";
import { serviceCardInfo } from "../../utils/serviceCardInfo";
import { InfoCards } from "../InfoCards/InfoCards";
import CustomCarousel from "../CustomCarousel/CustomCarousel";
import { useMediaQuery } from "react-responsive";
import { FiShoppingCart } from "react-icons/fi";

function HomePage() {
  useEffect(() => {
    (function () {
      document.documentElement.scrollTop = 0;
    })();
  }, []);

  const isSmall = useMediaQuery({
    query: "(max-width: 600px)",
  });

  return (
    <div>
      <main className="home-page-hero">
        <div className="cover">
          <div className="hero-container">
            <div className="hero-content">
              <Row style={{ marginTop: isSmall ? 30 : undefined }}>
                <Typography.Title
                  style={{
                    color: "white",
                    fontSize: isSmall ? 52 : 62,
                    maxWidth: isSmall ? 400 : 644,
                    marginBottom: 20,
                  }}
                >
                  Because Your Pets Deserve The Best
                </Typography.Title>
              </Row>
              <Row>
                <Typography.Title
                  style={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: 300,
                    maxWidth: 644,
                    marginBottom: 0,
                  }}
                >
                  {isSmall
                    ? "Our doctors partner with pet owners to provide the best medical and surgical care possible."
                    : "West Forsyth Animal Hospital was founded in the summer of 2022 by Dr. Erin Doiron. We are a brand-new, state-of-the-art hospital providing exceptional care to the dogs and cats of the surrounding Forsyth community. We have two veterinarians on staff: Dr. Erin Doiron and Dr. Pam Sullivan, who have a combined 35 years of veterinary experience. Our doctors partner with pet owners to provide the best medical and surgical care possible. Our recommendations and vaccination protocols are tailored to meet their pet’s specific needs. The doctors and staff members attend ongoing continuing education seminars to provide the most up-to-date information to our clients."}
                </Typography.Title>
              </Row>
              {isSmall && (
                <Row align="middle" style={{ marginTop: 40 }}>
                  <button
                    style={{
                      background: "#6daaa5",
                      padding: 16,
                      width: 150,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      fontSize: 16,
                      color: "white",
                      outline: "none",
                      border: "1px solid white",
                      cursor: "pointer",
                      marginRight: 10
                    }}
                    onClick={() =>
                      window.open(
                        "https://app.mavenlink.com/redirect?url=https%3A%2F%2Fwfah.use1.ezyvet.com%2Fexternal%2Fportal%23googtrans%28en%29",
                        "_blank"
                      )
                    }
                  >
                    Pet Portal
                  </button>
                  <button
                    onClick={() =>
                      window.open(
                        "https://westforsythanimalhospital.vetsfirstchoice.com/",
                        "_blank"
                      )
                    }
                    style={{
                      background: "white",
                      padding: 16,
                      width: 150,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      fontSize: 16,
                      color: "#6daaa5",
                      outline: "none",
                      border: "1px solid #6daaa5",
                      cursor: "pointer",
                    }}
                  >
                    <FiShoppingCart
                      style={{ marginRight: 10, color: "#6daaa5" }}
                    />{" "}
                    Shop Now
                  </button>
                </Row>
              )}
            </div>
          </div>
        </div>
      </main>
      <Row
        gutter={[48, 48]}
        style={{
          maxWidth: "100vw",
          padding: "60px 0px",
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}
        justify="center"
      >
        <InfoCards />
      </Row>
      {/* <Row justify="center" style={{ marginTop: 50 }}>
        <Typography.Title
          style={{
            textAlign: "center",
            marginBottom: 0,
            margin: isSmall ? "5px 10px" : "0 0 0px 0",
            color: "#6daaa5",
            fontSize: "52px",
            fontWeight: 200,
          }}
          level={1}
        >
          What We Offer?
        </Typography.Title>
      </Row> */}
      {/* <Row justify="center">
        <Typography.Title
          style={{
            textAlign: "center",
            margin: isSmall ? "0 50px 25px 25px" : "0 0 50px 0",
            color: "#3A3A3A",
            fontWeight: 100,
          }}
          level={4}
        >
          We offer a wide range of services which includes the following below
        </Typography.Title>
      </Row> */}
      {/* <Row
        gutter={[48, 48]}
        style={{
          marginBottom: 48,
          maxWidth: "100vw",
          marginLeft: 0,
          marginRight: 0,
        }}
        justify="center"
      >
        {serviceCardInfo.slice(0, 3).map((s) => (
          <Col span={6} xs={24} sm={16} md={18} lg={9} xl={9} xxl={6}>
            <OfferCard service={s} />
          </Col>
        ))}
      </Row> */}
      {/* <Row
        gutter={[48, 48]}
        style={{
          marginBottom: 48,
          maxWidth: "100vw",
          marginLeft: 0,
          marginRight: 0,
        }}
        justify="center"
      >
        {serviceCardInfo.slice(3, 6).map((s) => (
          <Col span={6} xs={24} sm={16} md={18} lg={9} xl={9} xxl={6}>
            <OfferCard service={s} />
          </Col>
        ))}
      </Row> */}
      <div className="testimonial-section">
        <Row justify="center">
          <Typography.Title
            style={{
              textAlign: "center",
              marginBottom: 50,
              color: "#6daaa5",
              fontSize: 52,
              fontWeight: 200,
            }}
            level={1}
          >
            What Do Our Clients Say?
          </Typography.Title>
        </Row>
        <CustomCarousel />
      </div>
      <div className="map-section">
        <Map />
      </div>
      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
