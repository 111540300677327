import { Card, Carousel, Col, Row, Typography } from "antd";
import { FaQuoteLeft } from "react-icons/fa";
import React from "react";
import { testimonials } from "../../utils/testimonials";
import { useMediaQuery } from "react-responsive";

const CustomCarousel = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const isSmall = useMediaQuery({
    query: "(max-width: 990px)",
  });
  return (
    <Carousel
      autoplay
      autoplaySpeed={8000}
      style={{ height: 650 }}
      afterChange={onChange}
    >
      <>
        <Row justify="center">
          <Col
            span={12}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            // style={{ display: "inline-block" }}
          >
            <Card style={{ padding: "10px 50px", height: 600 }}>
              <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 50,
                  fontWeight: 200,
                }}
                level={4}
              >
                {testimonials.test1.info}
              </Typography.Title>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  maxWidth: 1000,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 30,
                }}
                level={4}
              >
                — {testimonials.test1.name}
              </Typography.Title>
            </Card>
          </Col>
          {!isSmall && (
            <Col
              span={12}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              // style={{ display: "inline-block" }}
            >
              <Card style={{ padding: "10px 50px", height: 600 }}>
                <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
                <Typography.Title
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 50,
                    fontWeight: 200,
                  }}
                  level={4}
                >
                  {testimonials.test2.info}
                </Typography.Title>
                <Typography.Title
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    maxWidth: 1000,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 30,
                  }}
                  level={4}
                >
                  — {testimonials.test2.name}
                </Typography.Title>
              </Card>
            </Col>
          )}
        </Row>
      </>
      <>
        <Row style={{ display: "flex" }} justify="center">
          <Col
            span={12}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            // style={{ display: "inline-block" }}
          >
            <Card style={{ padding: "10px 50px", height: 600 }}>
              <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 50,
                  fontWeight: 200,
                }}
                level={4}
              >
                {testimonials.test1.info}
              </Typography.Title>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  maxWidth: 1000,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 30,
                }}
                level={4}
              >
                — {testimonials.test1.name}
              </Typography.Title>
            </Card>
          </Col>
          {!isSmall && (
            <Col
              span={12}
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              style={{ display: "inline-block" }}
            >
              <Card style={{ padding: "10px 50px", height: 600 }}>
                <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
                <Typography.Title
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 50,
                    fontWeight: 200,
                  }}
                  level={4}
                >
                  {testimonials.test2.info}
                </Typography.Title>
                <Typography.Title
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    maxWidth: 1000,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 30,
                  }}
                  level={4}
                >
                  — {testimonials.test2.name}
                </Typography.Title>
              </Card>
            </Col>
          )}
        </Row>
      </>
      {isSmall && (
        <Row style={{ display: "flex" }} justify="center">
          <Col
            span={12}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            style={{ display: "inline-block" }}
          >
            <Card style={{ padding: "10px 50px", height: 600 }}>
              <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 50,
                  fontWeight: 200,
                }}
                level={4}
              >
                {testimonials.test1.info}
              </Typography.Title>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  maxWidth: 1000,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 30,
                }}
                level={4}
              >
                — {testimonials.test1.name}
              </Typography.Title>
            </Card>
          </Col>
        </Row>
      )}
      {isSmall && (
        <Row style={{ display: "flex" }} justify="center">
          <Col
            span={12}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            style={{ display: "inline-block" }}
          >
            <Card style={{ padding: "10px 50px", height: 600 }}>
              <FaQuoteLeft size={60} style={{ color: "#6daaa57f" }} />
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 50,
                  fontWeight: 200,
                }}
                level={4}
              >
                {testimonials.test1.info}
              </Typography.Title>
              <Typography.Title
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  maxWidth: 1000,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 30,
                }}
                level={4}
              >
                — {testimonials.test1.name}
              </Typography.Title>
            </Card>
          </Col>
        </Row>
      )}
    </Carousel>
  );
};

export default CustomCarousel;
