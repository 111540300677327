import { Col, Divider, Image, Row, Typography } from "antd";
import React, { useEffect } from "react";
import Footer from "../Footer/Footer";

function OurStoryPage() {
  useEffect(() => {
    (function () {
      document.documentElement.scrollTop = 0;
    })();
  }, []);
  return (
    <Row justify="center" align="middle">
      <Col span={24}>
        <div className="about-banner" style={{ marginBottom: 50 }}>
          <p>About Us</p>
        </div>
        <Row
          justify="center"
          style={{ marginBottom: 50, maxWidth: "100vw" }}
          gutter={[20, 20]}
        >
          <Col span={15}>
            <Typography.Title
              style={{
                color: "#6daaa5",
                fontSize: "64px",
                fontWeight: 300,
                marginTop: 20,
              }}
            >
              Our Story.
            </Typography.Title>
            <Typography.Text style={{ fontSize: 20 }}>{story}</Typography.Text>
          </Col>
          <Col span={15}>
            <Image src="./ekg-large.webp" preview={false} />
          </Col>
          <Col span={15}>
            <Typography.Title
              style={{
                color: "#6daaa5",
                fontSize: "64px",
                fontWeight: 300,
                marginTop: 20,
              }}
            >
              Our Values.
            </Typography.Title>
          </Col>
          {coreValues.map((value, i) => (
            <Col span={15} key={i}>
              <Typography.Title
                level={3}
                style={{
                  // textAlign: "center",
                  color: "#6daaa5",
                  fontSize: "52px",
                  fontWeight: 200,
                  marginTop: 20,
                  // marginBottom: 0,
                }}
              >
                {value.title}
              </Typography.Title>
              <Typography.Text style={{ fontSize: 18 }}>
                {value.desc}
              </Typography.Text>
            </Col>
          ))}
        </Row>
        <Col style={{ marginTop: 50 }}>
          <Footer />
        </Col>
      </Col>
    </Row>
  );
}

const story =
  "Established in 2022, West Forsyth Animal Hospital is comprised of kind, devoted, and energetic individuals. We are dedicated to providing compassionate, high-quality care to our patients and clients in the West Forsyth area of Cumming, Georgia. We have a great love and respect for our patients and appreciation for our clients. We provide a full spectrum of veterinary services including comprehensive medical exams, wellness and preventative care, surgical and dental care, acute and chronic disease management, full in-house diagnostics and more! We hope to provide you with the education and knowledge to make sure your pet lives a long, happy, healthy life. Thank you for giving us the opportunity to serve you.";

const coreValues = [
  {
    title: "Compassion",
    desc: "We are committed to providing the most compassionate, ethical and quality care to our patients. We aim to treat all pet owners and staff members with fairness, understanding, kindness, and grace.",
  },
  {
    title: "Dedication",
    desc: "We are determined to do everything possible to give our patients the best possible outcome in all situations. We strive to provide the highest standard of patient care as well as pet owner service, education, and support.",
  },
  {
    title: "Respect",
    desc: "We respect our pet owners, colleagues, and co-workers by seeking to understand their experience and expectations. We communicate professionally, authentically, and proactively.",
  },
  {
    title: "Excellence",
    desc: "Provide high quality pet care by surpassing expectations and demonstrating a friendly, professional manner in all that we do. We strive to exceed the expectations of every client, every time. We excel in customer service and treat everyone with dignity.",
  },
];

export default OurStoryPage;
